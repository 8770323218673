<template>
  <div id="main_container">
    <div id="image_container">
      <img
          :src="require(`@/assets/img-150px/${img}`)"
          :srcset="require(`@/assets/img-100px/${img}`) + ' 100w,' + require(`@/assets/img-150px/${img}`) + ' 150w'"
          sizes="(min-width: 576px) 150px, 100px, (min-width: 576px) 100px"
          alt="steps info image"
      />
    </div>
    <div id="text">{{text}}</div>
  </div>
</template>

<script>
import url from "url";

export default {
  name: "HowItWorksStep",
  props: {
    img: {
      type: url,
      required: true,
    },
    text: {
      type: String,
      required: true,
    }
  },

  computed: {

  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main";

#main_container {
  max-width: 100%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include sm {
    max-width: 70%;
  }

  @include md {
    max-width: 200px;
  }

  #text {
    text-align: center;
    font-family: $font_content;
    font-size: large;
    margin-top: 1em;

  }

  #image_container {

    >img {
      width: 100px;
      height: auto;
      max-width: 100px;
      box-sizing: content-box;

      @include sm {
        width: 150px;
        max-width: 150px;
      }

      @include md {
        max-width: 100%;
      }
    }
  }
}

</style>