<template>
  <div id="main_container" v-resize="onResize">
    <Heading heading="How It Works"/>
    <div id="single_flow" v-for="k in 2" :key="k" :style="k === 1? style('#FFFFFF'): style('#FFFFFF')">
      <div id="flow_title">{{k === 1 ? 'Do you want to buy book?': 'Do you have books to sell?'}}</div>
      <div id="steps_container">
        <div id="step" v-for="(step, stepIndex) in steps[k - 1]" :key="stepIndex" >
          <HowItWorksStep :img="step.img" :text="step.text" />
          <div id="icon_container">
            <v-icon color="white" v-if="stepIndex !== steps[k - 1].length - 1">
              {{shouldArrowDown ? 'mdi-chevron-down': 'mdi-chevron-right'}}
            </v-icon>
          </div>
        </div>
    </div>

    </div>

  </div>
</template>

<script>
import Heading from "@/components/atom/Heading";
import HowItWorksStep from "@/components/MainApp/home/HowItWorks/HowItWorksStep";
import breakpoints from "@/assets/breakpoints";
import {buyerData, sellerData} from './data'

export default {
  name: "HowItWorks",
  components: {HowItWorksStep, Heading},
  data: () => ({
    windowSize: {
      width: 0,
      height: 0
    },
    steps: [buyerData, sellerData]
  }),

  computed: {
    shouldArrowDown() {
      return this.windowSize.width < breakpoints.md
    },


  },
  mounted () {
    this.onResize()
  },

  methods: {
    onResize () {
      this.windowSize = { width: window.innerWidth, height: window.innerHeight }
    },
    style(backgroundColor) {
      return `--bgColor--: ${backgroundColor};`
    }
  },

}
</script>

<style lang="scss" scoped>
@import "src/css/main";
$bgColor:var(--bgColor--);

#main_container {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md {
    margin-top: 3em;
  }

  #single_flow {
    background-color: $bgColor;
    margin-top: 2em;
    margin-bottom: 2em;

    #flow_title {
      font-size: xx-large;
      font-family: $font_heading;
      text-align: center;
      margin-left: 1.5em;
      margin-right: 1.5em;
      font-weight: bolder;
    }

    #steps_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 0;

      @include md {
        flex-direction: row;
        margin-right: 1em;
      }

      #step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include md {
          flex-direction: row;
          align-items: normal;
        }

        #icon_container {
          background: tomato;
          color: white;
          border-radius: 50%;
          align-self: center;
          margin-left: 0;

          @include md{
            margin-left: 1em;
          }
        }
      }
    }
  }

}
</style>