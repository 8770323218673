export const buyerData = [
    {
        img: 'location.png',
        text: "Provide your location"
    },
    {
        img: 'book.png',
        text: "Search and order the book"
    },
    {
        img: 'wait.png',
        text: "Wait for the book to be available at our Puco Point"
    },
    {
        img: 'book_submmition.png',
        text: "Hurray! Take your book and pay the bill"
    }
]

export const sellerData = [
    {
        img: 'books_seller.png',
        text: "List your book on the Pucoreads app"
    },
    {
        img: 'wait.png',
        text: "Wait for the order"
    },
    {
        img: 'delivery.png',
        text: "Deliver your book to nearest Puco Point"
    },
    {
        img: 'payment.png',
        text: "Receive your payment after the exchange completes"
    }
]

export default {sellerData, buyerData}