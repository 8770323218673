<template>
  <div id="hero-container">
    <div id="hero_main_content">

      <v-sheet
          color="white"
          elevation="1"
          class="banner"
      >
        <v-icon>mdi-information-outline</v-icon>
        <span><u>Pucoreads app</u> is coming soon on Play Store. Stay Tuned.</span>
      </v-sheet>

      <!-- ===================== Hero heading and subheading section start =============== -->
      <div id="heading_hero">
        <div id="hero_heading_prefix">To all book lovers and readers, Presenting...</div>
        <div id="pucoreads_text_hero">PUCOREADS</div>
      </div>

      <div id="subheading_hero">
        BUY, SELL, SHARE Books at your own convenience. Join our community to know who loves the books as you love near you.
      </div>

      <!-- ===================== Hero heading and subheading section end ============================= -->

      <!-- ===================== Hero call to action section start =================================== -->
      <div id="call_to_action_btn_container">
        <v-btn
            class="btn"
            id="see_features_call_to_action_btn"
            rounded
            color="white"
            v-on:click="scroll('features_container_home')"
        >
          See Features
        </v-btn>
        <v-btn
            class="btn"
            rounded
            outlined
            color="white"
            v-on:click="$router.push('/latest-updates')"
        >
          Latest Updates
        </v-btn>
      </div>

      <!-- ===================== Hero call to action section end =============== -->

      <!-- ===================== Hero email input section start =============== -->
      <div id="email_input_container">
        <div>Request Service and subscribe for future updates: </div>
        <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="Email"
            placeholder="Email..."
            outlined
            flat
            rounded
            dark
            clearable
        />
        <v-btn
            id="subscribe_btn"
            rounded
            color="white"
            :loading="subscribeBtnLoading"
            :disabled="subscribeBtnLoading || $store.state.auth.loading"
            v-on:click.stop="onSubmitBtnClicked()"
        >
          Request and Subscribe
        </v-btn>
      </div>
      <!-- ===================== Hero email input section end =============== -->

    </div>
    <div id="hero_svg_img_container">
      <img
          src="../../../../assets/pucoreads_hero_svg_v2.svg"
          alt="hero image"
      />
    </div>
  </div>
</template>

<script>
import { runTransaction, Timestamp, doc } from "firebase/firestore";
import {db} from "@/firebase";

export default {
  name:"Hero",
  data () {
    return {
      windowScrollOffset: 0,
      email: "",
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      subscribeBtnLoading: false,
    }
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },

    async onSubmitBtnClicked() {
      if(this.validEmail(this.email.toLowerCase())) {
        // email valid. Save it in db.
        this.subscribeBtnLoading = true
        try {
          await runTransaction(db, async (t) => {
            const emailDocRef = doc(db, "emails", this.email.toLowerCase())
            const docWithEmail = await t.get(emailDocRef)
            if(docWithEmail.exists()) {
              const newCount = docWithEmail.data().count + 1;
              t.update(emailDocRef, { count: newCount });
            }
            else {
              t.set(emailDocRef, {
                email: this.email.toLowerCase(),
                count: 1,
                timestamp: Timestamp.now()
              })
            }
          })

          this.$toast.success("Thank You for your interest. Here from us soon.")
        }
        catch (e) {
          console.error(e)
          this.$toast.error("Something went wrong. Try Again.")
        }
        this.subscribeBtnLoading = false
        this.email = ''

      }
      else {
        this.$toast.info("Provide Valid Email.")
      }
    },

    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },

  created() {

  }
}
</script>

<style lang="scss" scoped>
@import "../../../../css/main";

// mobile first design
#hero-container {
  padding: 5em 2em 1em;  // top, (right & left), bottom
  background-image: url("../../../../assets/hero_svg_mobile.svg");
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size:cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: $font_content;
  color: #ffffff;

  @include md {
    flex-direction: row;
    background-image: url("../../../../assets/hero_svg_3.svg");
  }

  @include lg {
    padding: 5em 5em 2em;
    background-image: url("../../../../assets/hero_svg_3.svg");
  }

  @include xl {
    padding: 5em 8em 2em;
    background-image: url("../../../../assets/hero_svg_3.svg");
  }

  #hero_main_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include lg {
      flex: 2;
    }

    .banner {
      width: 100%;
      border-radius: 10px;
      padding: 1em 1em 1em;
      margin-bottom: 2em;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-family: $font_content;
      font-weight: bold;
      color: darkgreen;
      font-size: large;

      :nth-child(1) {
        color: darkgreen;
      }

      :nth-child(2) {
        margin-left: 10px;
      }
    }

    /* ...............Hero heading start............. */
    #heading_hero {
      margin-bottom: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      #hero_heading_prefix {
        font-family: $font_funky;
        font-size: xx-large;
      }

      #pucoreads_text_hero {
        font-family: $font_funky;
        font-size: xx-large;
        font-weight: bold;
      }
    }
    /* ...............Hero heading end............. */

    /*................Hero Subheading start...............*/
    #subheading_hero {

    }
    /*................Hero Subheading end...............*/

    /*................Hero call to action section start............ */
    #call_to_action_btn_container {
      margin-bottom: 1em;
      margin-top: 1em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .btn {
        margin: 0.5em;
      }
    }
    /*................Hero call to action section end............ */

    /*................ Hero email input container start...........*/
    #email_input_container {
      width: 100%;
      margin-top: 1em;

      #subscribe_btn {
        float: right;
        margin-top: 0.4em;
      }

      div {
        margin-bottom: 1rem;
      }
    }
  }

  #hero_svg_img_container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    > img {
      align-self: center;
      width: 85%;
      height: auto;
      margin: 2em;

      @include sm {
        width: 70%;
      }

      @include lg {
        width: 90%;
        margin: 0;
      }
    }
  }
}



</style>
