// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDvidgm028l4N1ut_g_emfGBOfVSoHexnA",
    authDomain: "pucoreads-marketing.firebaseapp.com",
    projectId: "pucoreads-marketing",
    storageBucket: "pucoreads-marketing.appspot.com",
    messagingSenderId: "926150030923",
    appId: "1:926150030923:web:3c299a245fc75944893fce",
    measurementId: "G-XGM87SQ83G"
};

export default firebaseConfig