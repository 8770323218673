import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/vue-toastification'
import './plugins/vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStore, faListAlt, faCalendarCheck, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'


Vue.config.productionTip = false
library.add(faListAlt, faStore, faCalendarCheck, faInstagram, faFacebook, faLinkedin, faEnvelope, faBars)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
