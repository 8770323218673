<template>
  <div id="container" :style="style">
    <div>{{heading}}</div>
  </div>
</template>

<script>
import colors from "@/assets/colors";

export default {
  name: "Heading",
  components: {},
  props: {
    heading: {
      type: String,
      required: true,
    },
    color: {
      default: colors.color_primary
    }
  },
  computed: {
    style () {
      return 'color: ' + this.color;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/css/main";

#container {
  text-align: center;
  font-size: xxx-large;
  font-family: $font_funky;
  font-weight: bolder;
}
</style>