<template>
  <v-app id="inspire" v-scroll="onWindowScroll">


    <!--AppBar-->
    <Navbar
        :onHamburgerBtnClicked="function() { }"
    />
    <!--End of AppBar-->

    <v-main>
      <router-view />
    </v-main>

    <!--Footer-->
    <Footer />
    <!--End of Footer-->

  </v-app>
</template>

<script>
import Footer from "@/components/molecules/Footer";
import Navbar from "@/components/molecules/Navbar";
export default {
  name: "MainLayout",
  components:{
    Navbar,
    Footer,
  },
  props: {
    source: String,
  },
  data: () => ({
    windowScrollOffset: 0,
    drawer: false,
    drawerRight: false,
    right: false,
    left: false,
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),

  methods: {
    onWindowScroll() {
      this.windowScrollOffset = window.scrollY
    },

    onHamburgerBtnClickedInNavbar() {

    }
  }

}
</script>