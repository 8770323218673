<template>
  <div
      id="component_container"
      :class="[reverse? 'reverse_flex': 'normal_flex']"
  >
    <div
        id="image_container"
        v-if="img != null"

    >
      <img
          :src="img != null? img : ''"
          alt="features"
      />
    </div>
    <div id="info_container">
      <div id="title" v-if="title != null">{{title}}</div>
      <div v-if="isShowContent" id="content">{{content}}</div>
      <div id="actions">
        <v-btn text color="primary" id="left_action" v-if="leftAction" v-on:click="leftAction.func($root)">
          {{leftAction.text}}
        </v-btn>
        <v-btn text color="primary" id="right_action" v-if="rightAction" v-on:click="rightAction.func($root)">
          {{rightAction.text}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as url from "url";

export default {
  name: "BaseInfoComponent",
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },

    img: {
      type: url,
      required: false,
      default: null,
    },

    title: {
      type: String,
      required: false,
      default: null,
    },
    content: {
      type: String,
      required: true,
    },
    isShowContent: {
      type: Boolean,
      default: true,
    },
    leftAction: {
      type: Object,
      required: false,
    },
    rightAction: {
      type: Object,
      required: false,
    }
  },
  created() {
    console.log(this.reverse)
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main";


#component_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.5em 2em 1.5em;
  margin-top: 2em;
  margin-bottom: 2em;

  @include md {
    padding: 1.5em 5em 1.5em;
  }

  @include lg {
    padding: 1.5em 10em 1.5em;
  }

  #image_container {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 1em;

    > img {
      width: 80%;
      height: auto;

      @include sm {
        width: 100%;
      }

      @include lg {
        width: 400px;
      }
    }
  }

  #info_container {

    flex: 1;
    padding: 2em;

    #title {
      font-family: $font_heading;
      font-size: x-large;
      margin-bottom: 0.7em;
      font-weight: bolder;

      @include md {
        font-size: xx-large;
      }
    }

    #content {
      font-family: $font_content;
    }

    #actions {
      width: 100%;
      justify-content: space-between;
      margin-top: 1em;

      #right_action {
        float: right;
      }

      #left_action {
        float: left;
      }
    }
  }

}

.reverse_flex {
  flex-direction: column;

  @include sm {
    flex-direction: row-reverse;
  }
}

.normal_flex {
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }
}

</style>