<template>
  <v-footer
      app
      absolute
      color="#231f1a"
      class="footer white--text"
  >

    <section id="footer_section_container">

      <section class="footer_section" id="footer_head">
        <div class="footer_section_heading" ><img src="@/assets/pucosa-logo-full-white.svg" width="200"  alt="logo full"/> </div>
        <div>
          Pucoreads is a platform where you can connect with people who read and love books. You can buy, sell, and engage here.
        </div>
        <SocialLinks />
      </section>

      <div class="footer_section" v-for="(section, index) in footerSections" :key="index">
        <div class="footer_section_heading" v-if="section.heading !== ''">{{section.heading}}</div>
        <div v-for="(link, lIndex) in section.links" :key="lIndex" v-on:click="() => {
          if('to' in link) {
            $router.push(link.to)
          }
        }">
          {{link.text}}
        </div>
      </div>

      <div class="footer_section">
        <div class="footer_section_heading" >CONTACT US</div>
        <a href="mailto:pucoreads@pucosa.com">
          <font-awesome-icon :icon="['fa','envelope']" size="lg" />
          <span style="margin-left: 10px;">pucoreads@pucosa.com</span>
        </a>
      </div>
    </section>

    <section id="copyright_section">
      <span>Pucosa</span>
      <v-spacer></v-spacer>
      <span>{{ new Date().getFullYear() }} &copy; pucosa.com</span>
    </section>
  </v-footer>
</template>

<script>

import SocialLinks from "@/components/molecules/SocialLinks";
export default {
  name: "Footer",
  components: {SocialLinks},
  data: () => ({
    footerSections: [
      {
        heading: "QUICK LINKS",
        links:[
          {
            text: "Features",
            to: "/features-pucoreads"
          },
          {
            text: "What is Puco Point?",
            to: "/what-is-puco-point"
          },
          {
            text: "Latest Updates",
            to: "/latest-updates"
          },
        ]
      },
    ],


  })
}
</script>

<style scoped lang="scss">
@import "src/css/main";
.footer {
  margin-top: 2em;
  & {
    display: block;
  }
  font-family: $font_content;

  #footer_section_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include md{
      justify-content: center;
    }

    #footer_head {
      max-width: fit-content;

      @include xl{
        max-width: 300px;
      }
    }

    .footer_section {
      margin: 2em;
      line-height: 30px;

      a[href^="mailto:"]
      {
        color: white;
      }


      .footer_section_heading {
        font-weight: bolder;
        font-family: $font_heading;
        font-size: large;
        margin-bottom: 1em;
      }

      :nth-child(n+1) {
        font-family: $font_content;
        cursor: pointer;
      }
    }
  }

  #copyright_section {
    width: 100%;
    text-align: center;
    font-family: $font_content;
  }
}
</style>