<template>
  <div v-scroll="onScroll">
    <v-app-bar
        ref="navbar"
        id="navbar_container"
        :color="$route.name === 'Home' && !isScrolledDown? 'transparent' : '#ffffff'"
        v-if="visible"
        :flat="$route.name === 'Home' && !isScrolledDown"
        :app="$route.name !== 'Home'"
        :dark="$route.name === 'Home' && !isScrolledDown"
        elevate-on-scroll
        fixed
        clipped-right

    >
      <router-link to="/" id="pucosa_text">
        <img :src="require('../../assets/pucosa_logo.svg')"  alt="logo" id="logo"/>
        <v-toolbar-title :style="`color:${$route.name === 'Home' && !isScrolledDown? '#ffffff': '#303030'}` ">Pucosa</v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
          aria-label="Menu"
          @click.stop="moreClicked"
      >
        <template v-slot:default>
          <font-awesome-icon :icon="['fa','bars']" size="lg" />
        </template>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <!--NavigationRight-->
    <Drawer :toggle-visibility="toggleDrawerVisibility" :visible="drawerVisible"/>
    <!--End of NavigationRight-->
  </div>

</template>

<script>
import Drawer from "@/components/molecules/Drawer";
export default {
  name: "Navbar",
  components: {Drawer},
  data: () => ({
    isScrolledDown: false,
    drawerVisible: false,
  }),
  props: {
    visible: {
      type: Boolean,
      default: true,
    }
  },
  created() {
    this.onScroll()
  },
  methods: {
    onScroll: function() {
      const navbarRef = this.$refs.navbar
      const navbarOffset = navbarRef.$el.offsetTop
      this.isScrolledDown = window.pageYOffset > navbarOffset
    },
    toggleDrawerVisibility() {
      this.drawerVisible = !this.drawerVisible
    },
    moreClicked() {
      console.log("visible value is", this.drawerVisible)
      this.drawerVisible = !this.drawerVisible
    }
  }

}
</script>

<style scoped lang="scss">
@import "src/css/main";

#navbar_container {
  #pucosa_text {
    font-family: $font_heading;
    font-weight: bolder;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    #logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
</style>