<template>
  <v-navigation-drawer
      :value="visible"
      v-on:input="(isVisible) =>{
        if(visible !== isVisible) {
          toggleVisibility()
        }

      }"
      app
      clipped
      right
      temporary
      id="drawer"
  >
    <v-list nav>
      <v-subheader>Pucoreads</v-subheader>
      <v-list-item @click.stop="optionClicked(option.to)" v-for="(option, index) in drawerOptions" :key="index">
        <v-list-item-action>
          <font-awesome-icon :icon="option.icon" size="lg"/>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{option.text}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div style="margin-left: auto; margin-right: auto">
        <SocialLinks colour="#303030" center/>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import SocialLinks from "@/components/molecules/SocialLinks";
export default {
  name: "Drawer",
  components: {SocialLinks},
  data: () => ({
    drawerOptions: [
      {
        text: 'Features',
        icon: 'list-alt',
        to: '/features-pucoreads'
      },
      {
        text: 'What is Puco Point?',
        icon: 'store',
        to: '/what-is-puco-point'
      },
      {
        text: 'Latest Updates',
        icon: 'calendar-check',
        to: '/latest-updates'
      }
    ],
  }),
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    toggleVisibility: {
      type: Function,
      required: true,
    }
  },
  methods: {
    optionClicked(to) {
      this.$router.push(to)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/css/main";

#drawer {
  font-family: $font_content;
}
</style>