import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MainLayout from "@/Layout/MainLayout";
import Dashboard from "@/Layout/Dashboard";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: MainLayout,
        children: [
            {
                path: "",
                component: Home,
                name: 'Home'
            },
            {
                path: '/features-pucoreads',
                name: 'features_pucoreads',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import( '../views/FeaturesPucoreads.vue')
            },
            {
                path: '/latest-updates',
                name: 'latest_updates',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import( '../views/LatestUpdates.vue')
            },
            {
                path: '/what-is-puco-point',
                name: 'WhatIsPucoPoint',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import( '../views/WhatIsPucoPoint.vue')
            }
        ]
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        children: [
            {
                path: "",
                component: Home
            }
        ]
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( '../views/About.vue')
    },
    /*{
        path: '/',
        name: 'Home',
        component: SitePlaceholder,
    },*/
    {
        path: '/:catchAll(.*)',
        name: 'PageNotFound',
        component: () => import( '../components/Placeholders/PageNotFound')
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
