<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created() {
    this.$store.dispatch('initAuthState')
  }
};
</script>

<style lang="scss">
/* heading*/
@font-face {
  font-family: "Futura";
  font-display: swap;
  src: local("Futura"), url(./assets/fonts/futura_bold.ttf) format("truetype");
}
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap'); /*paragraph*/
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap'); /* funky */

  html, body {
    margin: 0;
  }
</style>
