export const goToFeaturesPucoreads = async (vue) => {
    await vue.$router.push('/features-pucoreads')
}

export const goToWhatIsPucoPoint = async (vue) => {
    await vue.$router.push('/what-is-puco-point')
}

const featuresData = [
    {
        title: "Find out your favourite book near you",
        content: "Someone near you might be having or reading a book you need right now! You will be able to access these listed books and order it easily.",
        img: require('@/assets/favourite_book_near_you.svg'),
        rightAction: {
            text: "Learn More...",
            func: goToFeaturesPucoreads,
        },
    },
    {
        title: "Convenience of getting your book safely using Puco Points",
        content: "Puco points will ensure safety of you and your book. You don't need to face a stranger at a strange place. You will get your book conveniently.",
        img: require('@/assets/puco_point.svg'),
        rightAction: {
            text: "Learn More...",
            func: goToFeaturesPucoreads,
        },
        leftAction: {
            text: "What is Puco Point?",
            func: goToWhatIsPucoPoint,
        },
    },
    {
        title: "Sell your old books from where you are",
        content: "Are there books piled up on the bookshelf! You have the opportunity to sell it without any hustle.",
        img: require('@/assets/old_books.svg'),
        rightAction: {
            text: "Learn More...",
            func: goToFeaturesPucoreads,
        },
    }
]

export default featuresData
