import app from "@/firebase"

const authModule = {
    state: () => ({
        loading: true,
        user: null,
        error: null,
    }),
    mutations: {
        loadingCompleted(state, {user}) {
            console.log("state is:",state)
            if(state.loading) state.loading = false
            state.user = user
            state.error = null
        },

        errorFound(state, {error}) {
            state.error = error.message
        }
    },
    actions: {
        async initAuthState({commit, state}) {
            try {
                console.log('Get Auth packages...')
                const {initializeAuth, indexedDBLocalPersistence, onAuthStateChanged, signInAnonymously} = await import('firebase/auth')
                const auth = initializeAuth(app, {
                    persistence: indexedDBLocalPersistence,
                    // No popupRedirectResolver defined
                });
                console.log('Auth state initializing...')
                onAuthStateChanged(auth, (user) => {
                    if(user) {
                        commit('loadingCompleted', {user})
                    }
                    else {
                        if(!state.error) signInAnonymously(auth).catch((e) => commit("errorFound", {e}))
                    }

                })
            }
            catch (e) {
                commit("errorFound", {e})
            }
        },
    },
    getters: {

    }
}

export default authModule