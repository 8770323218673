<template>
<div id="features_container_home">
  <Heading heading="Features"/>
  <BaseInfoComponent v-for="(feature, index) in features" :key="index"
      :content="feature.content"
      :title="feature.title" :img="feature.img" :reverse="index % 2 !== 0"
      :is-show-content="false"
                     :left-action="'leftAction' in feature? feature.leftAction : null"
                     :right-action="'rightAction' in feature? feature.rightAction : null"
  />
</div>
</template>

<script>
import BaseInfoComponent from "@/components/molecules/BaseInfoComponent";
import Heading from "@/components/atom/Heading";
import featuresData from "@/components/MainApp/home/Features/featuresData";

export default {
  name: "Features",
  components: {
    Heading,
    BaseInfoComponent,
  },
  data: () => ({
    features: featuresData
  })
}
</script>

<style lang="scss" scoped>
#features_container_home {
  margin-top: 1em;
}
</style>