<template>
  <div id="social_links" :class="{center_container: center}">
    <a v-for="(social, i) in socialLinks" :href="social.link" :key="i" target="_blank">
      <font-awesome-icon
        :icon="['fab', social.name]"
        size="2x"
        id="social_link_icon"
        :style="{color: colour}"
    /></a>
  </div>
</template>

<script>
export default {
  name: "SocialLinks",
  data: () => ({
    socialLinks: [
      {
        name: 'instagram',
        link: 'https://www.instagram.com/pucoreads/'
      },
      {
        name: 'facebook',
        link: 'https://www.facebook.com/pucoreads/'
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/pucoreads/'
      }
    ]
  }),

  props: {
    colour: {
      type: String,
      default: "#ffffff",
      required: false,
    },
    center: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">

#social_links {
  margin-top: 1em;
  display: flex;

  #social_link_icon{
    margin: 15px;
  }
}

.center_container {
  align-items: center;
  justify-content: center;
}

</style>