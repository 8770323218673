<template>
  <div class="home">
    <Hero/>
    <Features />
    <HowItWorks />
  </div>
</template>

<script>
import Hero from "@/components/MainApp/home/HeroSection/Hero";
import Features from "@/components/MainApp/home/Features/Features";
import HowItWorks from "@/components/MainApp/home/HowItWorks/HowItWorks";

  export default {
    name: 'Home',
    metaInfo: {
      meta: [
        {
          name: 'description',
          content: 'We present you our new upcoming service - Pucoreads. All book lovers and readers have a good news as Pucoreads is there to let you sell, exchange and buy books near you. Join Our community to know who loves book like you do.'
        },
        {
          name: 'author',
          content: 'Pucosa'
        },
        {
          name: 'keywords',
          content: 'how it works, how pucoreads work, pucoreads working, pucosa working, pucoreads, pucosa, books, reading, sell book, used books, upcoming service, upcoming, sell, buy, exchange, buy book, exchange book, book, book lover, websites for books, book app, book apps'
        }
      ]
    },
    components: {
      HowItWorks,
      Features,
      Hero,
    }
  }
</script>

<style scoped lang="scss">
.home {

}
</style>
